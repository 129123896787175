import styled from 'styled-components';
import { primary, box, space } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  flex: 1;
  padding: ${space - 5}px ${space}px;
  margin: 0 ${space / 2}px;
  margin-bottom: ${space}px;
  border-radius: 10px;
  background: ${box};

  ${MEDIA.TABLET`
    max-width: 100%;
    margin-bottom: ${space}px;
  `};

  h3 {
    font-size: 18px;
    line-height: 27px;
    color: ${primary};
    margin-bottom: ${space / 2}px;
  }
`;

export const Copy = styled.p`
  margin-bottom: 0;
`;
