import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Title from 'components/title';
import Spacer from 'components/spacer';
import Box from 'components/box';
import Tabs from 'components/tabs';
import TypeForm from 'components/typeform';
import { Content } from 'components/layout/layout.css';

import PhpJsPythonGoNet from './assets/php-js-python-go-net.svg';
import Flavour from './assets/flavour.svg';
import HowItWorks from './assets/how-it-works.svg';

import SlackIn from 'components/slackin';

const Index = ({ data }) => (
  <Layout>
    <section className="jumbotron">
      <Flavour />

      <Spacer />
      <Title as="h2" weight="light">
        {data.homeJson.jumbotron.title}
      </Title>
      <Spacer />
      <TypeForm />
      <Spacer />
      <SlackIn />
      <Spacer />
      <p className="lead">{data.homeJson.jumbotron.intro}</p>
    </section>

    <Box items={data.homeJson.box} />

    <Spacer size="3" />

    <Content id="what">
      <div className="grid">
        <div className="grid__column grid__column--70">
          <Title as="h2">{data.homeJson.what.title}</Title>
          {data.homeJson.what.lead ? (
            <p className="lead">{data.homeJson.what.lead}</p>
          ) : null}
          {data.homeJson.what.body
            ? data.homeJson.what.body.map((paragraph, i) => (
                <p key={i}>{paragraph}</p>
              ))
            : null}
        </div>
        <div className="grid__column center">
          <PhpJsPythonGoNet />
        </div>
      </div>
    </Content>

    <hr />

    <Content id="why">
      <Title as="h2" className="center">
        {data.homeJson.why.title}
      </Title>
      {data.homeJson.why.lead ? (
        <p className="lead center">{data.homeJson.why.lead}</p>
      ) : null}
      {data.homeJson.why.body
        ? data.homeJson.why.body.map((paragraph, i) => (
            <p key={i}>{paragraph}</p>
          ))
        : null}
    </Content>

    <hr />

    <Tabs items={data.homeJson.tabs} />

    <hr />

    <Content id="how">
      <div className="grid">
        <div className="grid__column grid__full">
          <Title as="h3">{data.homeJson.how.title}</Title>
          {data.homeJson.how.lead ? (
            <p className="lead center">{data.homeJson.how.lead}</p>
          ) : null}
          {data.homeJson.how.body
            ? data.homeJson.how.body.map((paragraph, i) => (
                <p key={i}>{paragraph}</p>
              ))
            : null}
        </div>
      </div>
    </Content>

    <HowItWorks />
    <Spacer size="2" />
    <Box items={data.homeJson.teaser1} />
    <Box items={data.homeJson.teaser2} />

    <hr />

    <Content id="faq">
      <Title as="h2" className="center">
        {data.homeJson.faq.title}
      </Title>
      <Spacer />
      <div className="grid">
        {data.homeJson.faq.questions.map((question, i) => (
          <div className="grid__column" key={i}>
            <Title as="h3" className="h4">
              {question.question}
            </Title>
            <p>{question.answer}</p>
          </div>
        ))}
      </div>
    </Content>

    <hr />

    <Content id="media">
      <Title as="h3" className="center">
        {data.homeJson.media.title}
      </Title>
      <p className="lead center">{data.homeJson.media.body}</p>
      <p className="center">
        <Link to={'/media'} className="btn btn-tertiary">
          {data.homeJson.media.button}
        </Link>
      </p>
    </Content>

    <Spacer size="2" />
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomeQuery {
    homeJson {
      jumbotron {
        title
        intro
      }
      what {
        title
        lead
        body
      }
      why {
        title
        lead
      }
      how {
        title
        lead
        body
      }
      faq {
        title
        questions {
          question
          answer
        }
      }
      media {
        title
        body
        button
      }
      box {
        title
        body
        theme
      }
      teaser1 {
        title
        body
        theme
      }
      teaser2 {
        title
        body
        theme
      }
      tabs {
        tab
        title
        quote
        lead
        body
        style
      }
    }
  }
`;
