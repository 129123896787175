import React from 'react';
import { PopupButton } from '@typeform/embed-react';
import { Container } from './typeform.css';

class TypeForm extends React.Component {
  constructor(propTypes) {
    super(propTypes);
    this.openForm = this.openForm.bind(this);
  }

  openForm() {
    this.typeformEmbed.typeform.open();
  }

  render() {
    return (
      <Container>
        <PopupButton
          id="jlI78c"
          style={{ top: 100 }}
          className="btn btn-primary"
        >
          Get early access
        </PopupButton>
        <div className="muted">
          Request an invitation for the first cloud implementation of Flavours •
          Powered by&nbsp;
          <a
            href="https://www.divio.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Divio
          </a>
          &nbsp;CMP
        </div>
      </Container>
    );
  }
}
export default TypeForm;
