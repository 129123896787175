import styled from 'styled-components';
import {
  primary,
  secondary,
  tertiary,
  quaternary,
  quinary,
  box,
  space,
  transition,
} from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

const radius = '10px';
const tab_hover = '#e9e3d0';

export const Container = styled.section`
  .react-tabs {
    -webkit-tap-highlight-color: transparent;
    margin: 50px 0;
  }

  .react-tabs__tab-list {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;

    font-size: 14px;
    line-height: 25px;
    font-weight: 500;

    padding: ${space / 3}px ${space / 3}px ${space / 3}px 0;
    margin: 0 0 ${space * 3}px;
    border-radius: ${radius};
    background: ${box};

    ${MEDIA.PHONE`
      display: block;
    `};
  }

  .react-tabs__tab {
    flex: 1;
    cursor: pointer;
    text-align: center;
    list-style: none;
    padding: 2px 0 ${space / 6}px 0;
    margin: 0 0 0 ${space / 3}px;
    border-radius: ${radius};
    transition: all ${transition}s;
    &:hover {
      background: ${tab_hover};
    }
  }

  .react-tabs__tab--selected {
    color: #fff;
    border-radius: ${radius};
    background-color: ${primary};
  }
  // different colors
  .react-tabs__tab:nth-child(1).react-tabs__tab--selected {
    background-color: ${secondary};
  }
  .react-tabs__tab:nth-child(2).react-tabs__tab--selected {
    background-color: ${quaternary};
  }
  .react-tabs__tab:nth-child(3).react-tabs__tab--selected {
    background-color: ${primary};
  }
  .react-tabs__tab:nth-child(4).react-tabs__tab--selected {
    background-color: ${quinary};
  }
  .react-tabs__tab:nth-child(5).react-tabs__tab--selected {
    background-color: ${tertiary};
  }

  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }

  .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
  }

  .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }

  .react-tabs__tab-panel {
    display: none;

    // custom alignment
    max-width: 600px;
    margin: 0 auto;

    transition: all ${transition}s;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }
`;
