import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Title from 'components/title';
import { Container } from './tabs.css';
// import Button, { ButtonSpacer } from 'components/button';
// import Spacer from 'components/spacer';

const Box = ({ items = [] }) => (
  <Container>
    <Tabs>
      <TabList>
        {items.map((item, i) => (
          <Tab key={i}>{item.tab}</Tab>
        ))}
      </TabList>
      {items.map((item, i) => (
        <TabPanel key={i}>
          <Title as="h4">{parse(item.title)}</Title>
          <blockquote className={'cite--' + item.style}>
            {item.quote}
          </blockquote>
          <p className="lead">{item.lead}</p>
          {item.body.map((body, j) => (
            <p key={j}>{body}</p>
          ))}
        </TabPanel>
      ))}
    </Tabs>
  </Container>
);

export default Box;

Box.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};
