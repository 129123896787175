import React from 'react';
import PropTypes from 'prop-types';
import Item from 'components/box/item';
import { Container } from './box.css';

const Box = ({ items = [] }) => (
  <Container>
    {items.map((item, i) => (
      <Item {...item} key={i} />
    ))}
  </Container>
);

Box.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Box;
