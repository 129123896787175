import React from 'react';
import PropTypes from 'prop-types';
import Title from 'components/title';
import { Container, Copy } from './item.css';

const Item = ({ title, body, theme = '' }) => (
  <Container className={'box ' + theme}>
    <Title as="h3">{title}</Title>
    <Copy>{body}</Copy>
  </Container>
);

Item.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  theme: PropTypes.string,
};

export default Item;
