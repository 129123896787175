import React from 'react';
import { Container } from './slackin.css';

const SlackIn = () => {
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();

    window.open(
      'https://slack.flavours.dev/',
      'Get Slack invite',
      `width=550, height=600, toolbar=no, location=no, statusbar=no,
       menubar=no, scrollbars=no, resizable=no, location=no, centerscreen`
    );
  };

  return (
    <Container className="slackin">
      <a
        href="https://slack.flavours.dev/"
        target="_blank"
        rel="noopener noreferrer"
        className="slackin__anchor"
        onClick={handleClick}
      >
        <span className="slackin__text">Join us on </span>
        <img
          src="https://slack.flavours.dev/badge.svg"
          alt="Visit our Slack channel"
          className="slackin__image"
        />
      </a>
    </Container>
  );
};

export default SlackIn;
