import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: -5px;

  .slackin__anchor {
    text-decoration: none;
    font-size: 14px;
    color: #808080;
  }

  .slackin__text {
    position: relative;
    left: 0;
    top: -5px;

    padding-right: 5px;
    text-transform: uppercase;
  }
`;
