import styled from 'styled-components';
import { space, quinary, senary, tertiary } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-left: -${space / 2}px;
  margin-right: -${space / 2}px;

  ${MEDIA.TABLET`
    display: block;
  `};

  .box--quinary h3 {
    color: ${quinary};
  }
  .box--senary h3 {
    color: ${senary};
  }
  .box--tertiary h3 {
    color: ${tertiary};
  }
`;
